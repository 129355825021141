<template>
	<div class="recurring-kanban">
		<div class="kanban-view-list">
			<v-flex v-for="(row, index) in recurringData" :key="index" class="kanban-innerlist">
				<div class="list-title">
					<div class="d-flex">
						<div class="fw-600 title-text">{{ row.status.text }}</div>
						<v-spacer></v-spacer>
						<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
					</div>
					<div class="mt-1" v-if="row.status && row.status.status_color">
						<v-chip class="mb-1 mr-1" label :color="`${row.status.status_color} white--text`" x-small>
							{{ row.list.length }} Recurring
						</v-chip>
					</div>
				</div>
				<Container
					group-name="leadkanbanlist"
					v-on:drop="onDrop(index, row.list, $event, row.status.value)"
					:get-child-payload="(e) => getChildPayload(row.list, index, e)"
					:drop-placeholder="dropPlaceholderOptions"
					class="overflow-list-item"
					:id="!row.list.length ? 'blank-list' : ''"
				>
					<Draggable v-model="row.status.value" v-for="(data, index) in row.list" :key="index" class="">
						<div
							:class="`${row.status.status_color} lighten-5 list-items`"
							v-on:click="pushToRoute('recurring-details', { id: data.id })"
						>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Barcode : </span>
									<template v-if="data && data.barcode">
										<span class="fw-500">{{ data.barcode }}</span>
									</template>
									<template v-else>no barcode</template>
								</div>
							</div>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Contact Person : </span>
									<template v-if="data && data.contact_person">
										<span class="fw-500">{{ data.contact_person }}</span>
									</template>
									<template v-else>no contact person</template>
								</div>
							</div>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Company Name : </span>
									<template v-if="data && data.company_name">
										<span class="fw-500">{{ data.company_name }}</span>
									</template>
									<template v-else>no company name</template>
								</div>
							</div>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Phone Number : </span>
									<template v-if="data && data.primary_number">
										<span class="fw-500">{{ data.primary_number }}</span>
									</template>
									<template v-else>no phone number</template>
								</div>
							</div>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Email : </span>
									<template v-if="data && data.primary_email">
										<span class="fw-500">{{ data.primary_email }}</span>
									</template>
									<template v-else>no email</template>
								</div>
							</div>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Web URL (Target) : </span>
									<template v-if="data && data.web_url">
										<span class="fw-500">{{ data.web_url }}</span>
									</template>
									<template v-else>no web url</template>
								</div>
							</div>
							<!-- <v-checkbox v-on:click.stop="" hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
						</div>
					</Draggable>
				</Container>
			</v-flex>
		</div>
	</div>
</template>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
//import ProjectMember from "@/view/module/project/ProjectMember";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import { filter, cloneDeep } from "lodash";
export default {
	name: "RecurringKanban",
	components: {
		Container,
		Draggable,
		//ProjectMember,
	},
	data() {
		return {
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			manageNote: false,
			tagItems: ["Software", "SEO"],
			filter_tag: "Software",
			labelItems: ["High", "Medium", "Low"],
			filter_label: "High",
			userItems: ["Everyone"],
			filter_user: "Everyone",
			statusvalue: 0,
			recurringId: 0,
			recurringData: [],
		};
	},
	watch: {
		$route: {
			handler() {
				const { query } = this.$route;
				console.log(query);
				this.getRecurringListing(query);
			},
		},
	},
	methods: {
		getRecurringListing(query) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `recurring`,
					data: {
						status: "all",
						...query,
					},
				})
				.then((data) => {
					const output = [];
					for (let i = 0; i < data.status.length; i++) {
						if (data.status[i].value == 4) {
							continue;
						}
						output.push({
							status: data.status[i],
							list: filter(data.tbody, { status: data.status[i].text }),
						});
					}
					this.recurringData = output;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		onDrop(index, collection, dropResult, dropStatus) {
			const { addedIndex } = dropResult;

			if (addedIndex != null) {
				this.statusdata = dropStatus;
			}
			if (addedIndex != null) {
				this.update_loading = true;
				this.$store
					.dispatch(PATCH, { url: `recurring/${this.recurringId}/${this.statusdata}` })
					.then(() => {
						this.getRecurringListing();
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.update_loading = false;
					});
			}

			this.recurringData[index].list = this.applyDrag(collection, dropResult);
		},
		getChildPayload(row, index, event) {
			this.recurringId = row[event].id;
			return this.recurringData[index];
		},

		applyDrag(output, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult;
			if (removedIndex === null && addedIndex === null) return output;
			const result = cloneDeep(output);
			let itemToAdd = payload.list[addedIndex];
			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0];
			}
			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd);
			}
			return result;
		},
	},
	mounted() {
		this.getRecurringListing();
	},
};
</script>
